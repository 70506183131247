
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MainBoard from "./MainBoard";
import Status from "./Status";

const width = 1600;

ReactDOM.render(
    <React.StrictMode>
        <h1>Social Distancing: Flattening The Curve</h1> 
        <Status width={width} height={64}/>
      <MainBoard width={width} height={600}/>
      <p>This is a game to teach people about how public health measures can help reduce the toll of a pandemic.</p>
    </React.StrictMode>,
    document.getElementById("root")
  );