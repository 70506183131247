import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classes from '*.module.css';

interface StatusProps {
    width: number;
    height: number;
}



export default function Status({ width, height }: StatusProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            status_bar: {
                width: width,
                height: height,
                border: 'none',
                borderCollapse: 'collapse',
                margin: '5px',
            },
            status_cell: {
                border: '1px solid #ccc'
            },
            summary_number: {
                textAlign: 'center',
                fontSize: '24px',
                display: 'block',
            },
            summary_number_label: {
                textAlign: 'center',
                fontSize: '11px',
                display: 'block',
            }
        }),
    );
    const classes = useStyles();
    const [Money, setMoney] = React.useState<number>(0);
    const [Dead, setDead] = React.useState<number>(0);
    const [Survived, setSurvived] = React.useState<number>(0);
    const [Infected, setInfected] = React.useState<number>(0);
    const [Untouched, setUntouched] = React.useState<number>(0);
    return (
        <table className={classes.status_bar}>
            <tr>
                <td className={classes.status_cell}>
                    <Typography className={classes.summary_number}>{Money}</Typography>
                    <Typography className={classes.summary_number_label}>Money</Typography>
                </td>
                <td className={classes.status_cell}>
                    <Typography className={classes.summary_number}>{Dead}</Typography>
                    <Typography className={classes.summary_number_label}>Dead</Typography>
                </td>
                <td className={classes.status_cell}>
                    <Typography className={classes.summary_number}>{Survived}</Typography>
                    <Typography className={classes.summary_number_label}>Survived</Typography>
                </td>
                <td className={classes.status_cell}>
                    <Typography className={classes.summary_number}>{Infected}</Typography>
                    <Typography className={classes.summary_number_label}>Infected</Typography>
                </td>
                <td className={classes.status_cell}>
                    <Typography className={classes.summary_number}>{Untouched}</Typography>
                    <Typography className={classes.summary_number_label}>Untouched</Typography>
                </td>
            </tr>
        </table>
    );
};