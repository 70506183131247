import React from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type Coordinate = {
    x: number;
    y: number;
};

interface CanvasProps {
    width: number;
    height: number;
}

const W_KEY_CODE = 87;
const S_KEY_CODE = 83;
const A_KEY_CODE = 65;
const D_KEY_CODE = 68;

const speed = 10;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boardCanvas: {
      border: '1px solid black',
    },
    hidden: {
        display: 'none'
    }
  }),
);

export default function MainBoard({ width, height }: CanvasProps) {
    const classes = useStyles();
    const boardCanvas = React.useRef<HTMLCanvasElement>(null);
    const playerImg = React.useRef<HTMLImageElement>(null);
    const [playerPosition, setPlayerPosition] = React.useState<Coordinate>({x: width/2, y: height/2});

    const drawBoard = () => {
        if (!boardCanvas.current) {
            return;
        }
        const canvas: HTMLCanvasElement = boardCanvas.current;
        const context = canvas.getContext('2d');
        
        if (context && playerImg.current) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.strokeStyle = 'red';
            context.lineJoin = 'round';
            context.lineWidth = 5;

            context.drawImage(playerImg.current, playerPosition.x, playerPosition.y);
            context.beginPath();
            context.arc(playerPosition.x, playerPosition.y, 10, 0, 2 * Math.PI);
            context.stroke();
        }
    };

    const onKeyDown = React.useCallback((event: KeyboardEvent) => {
        let code = event.keyCode;
        if (code === W_KEY_CODE) {
            setPlayerPosition({x:playerPosition.x, y:playerPosition.y - speed})
        }
        
        if (code === S_KEY_CODE) {
            setPlayerPosition({x:playerPosition.x, y:playerPosition.y + speed})
        }
        
        if (code === A_KEY_CODE) {
            setPlayerPosition({x:playerPosition.x - speed, y:playerPosition.y})
        }
        
        if (code === D_KEY_CODE) {
            setPlayerPosition({x:playerPosition.x + speed, y:playerPosition.y})
        }
        console.log("keydown: pos: " + JSON.stringify(playerPosition));
        drawBoard();
    }, [playerPosition]);
    
    React.useEffect(() => {
        if (!boardCanvas.current) {
            return;
        }
        //const canvas: HTMLCanvasElement = boardCanvas.current;
        window.addEventListener('keydown', onKeyDown);
        drawBoard();
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);  

    const endTurn = () => {
        console.log("End Turn");
    };

    return (
        <div>
            <canvas className={classes.boardCanvas} ref={boardCanvas} width={width} height={height}>
            </canvas>
            <Button
            variant="contained"
            color="primary"
            onClick={endTurn}
            >End Turn</Button>
            <div className={classes.hidden}>
                <img ref={playerImg} src='player.png'/>
            </div>
        </div>
    );
}